import { CustomerDoc } from '@livekatsomo/models';
import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { CustomersContext } from '@livekatsomo/web/contexts';
import {
  addCustomer,
  getCustomers,
  validateCustomerSlug,
  deleteCustomer,
} from '@livekatsomo/web/data';
import { QueryConstraint, orderBy } from 'firebase/firestore';
import { Suspense, useState } from 'react';

interface CustomersProviderProps {
  children: JSX.Element;
  queryConstraints?: QueryConstraint[];
  initialState?: CustomerDoc[];
}

export function CustomersProvider({
  children,
  queryConstraints: initialQueryConstraints = [],
  initialState,
}: CustomersProviderProps) {
  const [queryConstraints, setQueryConstraints] = useState<QueryConstraint[]>([
    orderBy('name', 'asc'),
  ]);

  const options: Parameters<typeof getCustomers>[0] = {
    queryConstraints: [...queryConstraints, ...initialQueryConstraints],
  };

  const store = getOrCreateMethodStore(getCustomers, options, initialState);

  return (
    <CustomersContext.Provider
      value={{
        store,
        setQueryConstraints,
        addCustomer,
        validateCustomerSlug,
        deleteCustomer,
      }}
    >
      <Suspense>{children}</Suspense>
    </CustomersContext.Provider>
  );
}
