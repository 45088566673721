import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { SpeakersContext } from '@livekatsomo/web/contexts';
import {
  getSpeakers,
  addSpeaker,
  updateSpeaker,
  deleteSpeaker,
  updateSpeakersOrder,
} from '@livekatsomo/web/data';
import { QueryConstraint } from 'firebase/firestore';
import { Suspense } from 'react';

interface SpeakersProviderProps {
  eventId: string;
  children: JSX.Element;
  queryConstraints?: QueryConstraint[];
}

export function SpeakersProvider({ eventId, children }: SpeakersProviderProps) {
  if (!eventId) throw new Error('eventId is required');

  const options: Parameters<typeof getSpeakers>[0] = {
    eventId,
  };

  const store = getOrCreateMethodStore(getSpeakers, options);

  return (
    <SpeakersContext.Provider
      value={{
        store,
        addSpeaker: (speaker, setProgress) =>
          addSpeaker(eventId, speaker, setProgress),
        updateSpeaker: (speaker, setProgress) =>
          updateSpeaker(eventId, speaker, setProgress),
        deleteSpeaker: (speakerId) => deleteSpeaker(eventId, speakerId),
        updateOrder: eventId
          ? (speakers) => updateSpeakersOrder(eventId, speakers)
          : undefined,
      }}
    >
      <Suspense>{children}</Suspense>
    </SpeakersContext.Provider>
  );
}
