import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { SurveyFormContext } from '@livekatsomo/web/contexts';
import { getForm, getSubmission, submitForm } from '@livekatsomo/web/data';
import { useAuthentication } from '@livekatsomo/web/data-hooks';
import { DocumentData } from 'firebase/firestore';
import { useCallback, useEffect, useState, useSyncExternalStore } from 'react';

type FormsProviderProps = {
  children: JSX.Element;
  eventId: string;
  formId?: string | null;
};

const stateMap = new WeakMap<() => Promise<unknown>>();

export function useAsyncStore<TState>({
  initialValue,
  callback,
}: {
  initialValue?: TState;
  callback: () => Promise<TState>;
}) {
  // Setup listeners
  const listeners = new Set<() => void>();

  // Prepare state
  if (!stateMap.has(callback)) {
    stateMap.set(callback, {
      loading: true,
      error: null,
      value: initialValue !== undefined ? initialValue : callback(),
    });
  }

  // Trigger callback
  callback()
    .then((value) => {
      const state = stateMap.get(callback);
      state.value = value;
      state.loading = false;
      for (const listener of listeners) {
        listener();
      }
    })
    .catch((error) => {
      const state = stateMap.get(callback);
      state.error = error;
      state.loading = false;
    });

  const store = useSyncExternalStore(
    (onStoreChange: () => void) => {
      listeners.add(onStoreChange);
      return () => listeners.delete(onStoreChange);
    },
    () => {
      const state = stateMap.get(callback);
      if (state.error) throw state.error;
      if (state.value instanceof Promise) throw state.value;
      return state.value;
    },
    () => initialValue,
  );

  const setState = (value: TState) => {
    const state = stateMap.get(callback);
    state.value = value;
    Set.prototype.forEach.call(listeners, (listener) => {
      listener();
    });
  };

  return [store, setState];
}

export function SurveyFormProvider({
  children,
  eventId,
  formId,
}: FormsProviderProps) {
  const { auth, signInAnonymously } = useAuthentication();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const uid = auth.currentUser?.uid;

  useEffect(() => {
    if (!formId || !uid) return;
    getSubmission(eventId, formId, uid).then((submission) => {
      setIsSubmitted(!!submission);
    });
  }, [uid, eventId, formId]);

  const handleSubmitForm = useCallback(
    async (values: DocumentData) => {
      if (!formId) return;
      if (!uid) {
        const user = await signInAnonymously();
        if (!user) return;
        await submitForm(eventId, formId, user.uid, values);
        setIsSubmitted(true);
      } else {
        await submitForm(eventId, formId, uid, values);
      }
      setIsSubmitted(true);
    },
    [eventId, formId, signInAnonymously, uid],
  );

  if (!eventId) {
    throw new Error('SurveyFormProvider requires an eventId');
  }

  if (!formId || !auth) {
    return children;
  }

  const options: Parameters<typeof getForm>[0] = {
    eventId,
    formId,
  };

  const store = getOrCreateMethodStore(getForm, options, null);

  return (
    <SurveyFormContext.Provider
      value={{
        store,
        submitForm: handleSubmitForm,
        isSubmitted,
      }}
    >
      {children}
    </SurveyFormContext.Provider>
  );
}
