import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { SlideDecksContext } from '@livekatsomo/web/contexts';
import {
  getSlideDecks,
  uploadSlideDeck,
  updateSlideDeck,
  deleteSlideDeck,
  uploadSlideDecks,
  getSlideDeckUsedIn,
} from '@livekatsomo/web/data';
import { QueryConstraint } from 'firebase/firestore';
import { Suspense } from 'react';

interface SlideDecksProviderProps {
  eventId: string;
  trackId: string;
  children: JSX.Element;
  queryConstraints?: QueryConstraint[];
}

export function SlideDecksProvider({
  eventId,
  trackId,
  children,
}: SlideDecksProviderProps) {
  if (!eventId) throw new Error('eventId is required');

  const options: Parameters<typeof getSlideDecks>[0] = {
    eventId,
    trackId,
  };

  const store = getOrCreateMethodStore(getSlideDecks, options);

  return (
    <SlideDecksContext.Provider
      value={{
        store,
        uploadSlideDeck: (slideDeck, setProgress) =>
          uploadSlideDeck(eventId, trackId, slideDeck, setProgress),
        updateSlideDeck: (slideDeckId, slideDeckFormData, setProgress) =>
          slideDeckId
            ? updateSlideDeck(
                eventId,
                trackId,
                slideDeckId,
                slideDeckFormData,
                setProgress,
              )
            : Promise.reject('No slide'),
        deleteSlideDeck: (slideDeckId) =>
          deleteSlideDeck(eventId, trackId, slideDeckId),
        uploadSlideDecks: (files) => uploadSlideDecks(eventId, trackId, files),
        onGetSlideDeckUsedIn: (slideDeckId) =>
          getSlideDeckUsedIn(eventId, trackId, slideDeckId),
      }}
    >
      <Suspense>{children}</Suspense>
    </SlideDecksContext.Provider>
  );
}
