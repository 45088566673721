import { AgendaItemDoc } from '@livekatsomo/models';
import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { AgendaContext } from '@livekatsomo/web/contexts';
import {
  getAgendaItems,
  addAgendaItem,
  updateAgendaItem,
  deleteAgendaItem,
  updateAgendaOrder,
  updateTrack,
} from '@livekatsomo/web/data';
import { useEvent } from '@livekatsomo/web/data-hooks';
import { QueryConstraint, serverTimestamp } from 'firebase/firestore';
import { Suspense } from 'react';

interface AgendaItemsProviderProps {
  children: JSX.Element;
  trackId: string;
  queryConstraints?: QueryConstraint[];
}

export function AgendaItemsProvider({
  children,
  trackId,
}: AgendaItemsProviderProps) {
  const { event } = useEvent();
  if (!event) throw new Error('event is required');

  const options: Parameters<typeof getAgendaItems>[0] = {
    eventId: event.id,
    trackId: trackId,
  };

  const store = getOrCreateMethodStore(getAgendaItems, options);

  return (
    <AgendaContext.Provider
      value={{
        store,
        addAgendaItem: (agendaItem) =>
          trackId
            ? addAgendaItem(event.id, trackId, agendaItem)
            : Promise.resolve(),
        updateAgendaItem: (agendaItem) =>
          updateAgendaItem(event.id, agendaItem.id, agendaItem),
        deleteAgendaItem: (agendaItemId) =>
          trackId
            ? deleteAgendaItem(event.id, trackId, agendaItemId)
            : Promise.resolve(),
        updateOrder: trackId
          ? (agendaItems) => updateAgendaOrder(event.id, trackId, agendaItems)
          : undefined,
        setAsCurrent: async (agendaItem) => {
          await handleSetAsCurrent(event.id, trackId, agendaItem);
        },
      }}
    >
      <Suspense>{children}</Suspense>
    </AgendaContext.Provider>
  );
}

async function handleSetAsCurrent(
  eventId: string,
  trackId: string,
  agendaItem: AgendaItemDoc,
) {
  return Promise.all([
    updateTrack(eventId, trackId, {
      currentAgendaItemId: agendaItem.id,
    }),
    updateAgendaItem(eventId, agendaItem.id, {
      timestamp: serverTimestamp() as unknown as Date,
    }),
  ]);
}
