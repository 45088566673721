import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { SubmissionsContext } from '@livekatsomo/web/contexts';
import { deleteSubmission, getSubmissions } from '@livekatsomo/web/data';

type FormSubmissionsProviderProps = {
  children: JSX.Element;
  eventId: string;
  formId?: string | null;
};

export function FormSubmissionsProvider({
  children,
  eventId,
  formId,
}: FormSubmissionsProviderProps) {
  if (!eventId) {
    throw new Error('FormSubmissionsProvider requires an eventId');
  }

  if (!formId) {
    throw new Error('FormSubmissionsProvider requires a formId');
  }

  const options: Parameters<typeof getSubmissions>[0] = {
    eventId,
    formId,
  };

  const store = getOrCreateMethodStore(getSubmissions, options);

  return (
    <SubmissionsContext.Provider
      value={{
        store,
        deleteSubmission: (submissionId) =>
          deleteSubmission(eventId, formId, submissionId),
      }}
    >
      {children}
    </SubmissionsContext.Provider>
  );
}
