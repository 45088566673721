import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { SponsorsContext } from '@livekatsomo/web/contexts';
import {
  getSponsors,
  addSponsor,
  updateSponsor,
  deleteSponsor,
  updateSponsorOrder,
} from '@livekatsomo/web/data';
import { QueryConstraint } from 'firebase/firestore';
import { Suspense } from 'react';

interface SponsorsProviderProps {
  eventId: string;
  children: JSX.Element;
  queryConstraints?: QueryConstraint[];
}

export function SponsorsProvider({ eventId, children }: SponsorsProviderProps) {
  if (!eventId) throw new Error('eventId is required');

  const options: Parameters<typeof getSponsors>[0] = {
    eventId,
  };

  const store = getOrCreateMethodStore(getSponsors, options);

  return (
    <SponsorsContext.Provider
      value={{
        store,
        addSponsor: (sponsor, setProgress) =>
          addSponsor(eventId, sponsor, setProgress),
        updateSponsor: (sponsor, setProgress) =>
          updateSponsor(eventId, sponsor, setProgress),
        deleteSponsor: (sponsorId) => deleteSponsor(eventId, sponsorId),
        updateOrder: (sponsors) => updateSponsorOrder(eventId, sponsors),
      }}
    >
      <Suspense>{children}</Suspense>
    </SponsorsContext.Provider>
  );
}
