import { AssetDoc } from '@livekatsomo/models';
import { LiveAvatar } from '@livekatsomo/web/ui-components/avatar';
import ImageIcon from '@mui/icons-material/Image';
import MoreVert from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';
import { AssetChip } from './AssetChip';

/**
 * Props for the AssetListItem component.
 */
export interface AssetListItemProps {
  /**
   * The asset object to be rendered.
   */
  asset: AssetDoc;
  /**
   * The function to be called when the menu button is clicked.
   *
   * @param event - The click event.
   * @param asset - The asset object associated with the menu button.
   * @returns
   */
  onMenuClick: (event: React.MouseEvent<HTMLElement>, asset: AssetDoc) => void;
}

/**
 * Renders a single asset item in a list.
 *
 * @returns The JSX element representing the asset item.
 */
export function AssetListItem({ asset, onMenuClick }: AssetListItemProps) {
  const { t } = useTranslation();
  let process: ReactNode = null;

  process = asset.purpose
    ? asset.purpose.map((purpose) => (
        <AssetChip key={purpose} purpose={purpose}></AssetChip>
      ))
    : null;

  if (
    asset.status === 'processing' ||
    asset.status === 'uploading' ||
    asset.status === 'uploaded'
  ) {
    process = <LinearProgress variant="indeterminate" />;
  }
  if (asset.status === 'error') {
    process = t('Asset processing failed');
  }

  return (
    <ListItem
      secondaryAction={
        <IconButton
          edge="end"
          onClick={(event) => {
            event.stopPropagation();
            onMenuClick(event, asset);
          }}
        >
          <MoreVert />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <LiveAvatar asset={asset} name={asset.filename}>
          <ImageIcon />
        </LiveAvatar>
      </ListItemAvatar>
      <ListItemText
        sx={{
          '.MuiListItemText-primary': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        primary={asset.filename}
        secondary={
          <span>
            {asset.description}
            {process}
          </span>
        }
      />
    </ListItem>
  );
}
