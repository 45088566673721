import { EventDoc } from '@livekatsomo/models';
import { getOrCreateMethodStore, useStore } from '@livekatsomo/suspense';
import { EventContext } from '@livekatsomo/web/contexts';
import {
  addJournalEvent,
  getEventById,
  updateEvent,
  updateEventTheme,
  uploadEventPoster,
  validateEventSlug,
  updateEventLayout,
  getEventIdBySlug,
} from '@livekatsomo/web/data';
import { useCustomer } from '@livekatsomo/web/data-hooks';
import { QueryConstraint } from 'firebase/firestore';
import { Suspense } from 'react';

type EventByIdProviderProps = {
  children: JSX.Element;
  initialState?: EventDoc;
  eventId: string;
};

export function EventByIdProvider({
  children,
  eventId,
  initialState,
}: EventByIdProviderProps) {
  if (!eventId) {
    throw new Error('EventProvider requires an id');
  }

  const options: Parameters<typeof getEventById>[0] = {
    eventId,
  };

  const store = getOrCreateMethodStore(getEventById, options, initialState);

  return (
    <EventContext.Provider
      value={{
        store,
        uploadEventPoster,
        updateEvent: (event) => updateEvent(eventId, event),
        updateEventTheme,
        validateEventSlug,
        addJournalEvent: (journalEvent) =>
          addJournalEvent(eventId, journalEvent),
        updateEventLayout: async (layout) => {
          await updateEventLayout(eventId, layout);
        },
      }}
    >
      <Suspense>{children}</Suspense>
    </EventContext.Provider>
  );
}

type EventBySlugProviderProps = {
  children: JSX.Element;
  initialState?: EventDoc;
  initialEventId?: string;
  queryConstraints?: QueryConstraint[];
  slug: string;
} & ({ id: string } | { slug: string });

export function EventBySlugProvider({
  children,
  slug,
  initialState,
  queryConstraints,
  initialEventId,
}: EventBySlugProviderProps) {
  console.log('EventBySlugProvider called', slug);
  if (!slug) {
    throw new Error('EventProvider requires a slug');
  }

  const { customer } = useCustomer();
  console.log('EventBySlugProvider customer', customer);

  if (!customer) throw new Error('Customer not found');

  const options: Parameters<typeof getEventIdBySlug>[0] = {
    customerId: customer?.id,
    slug,
    queryConstraints,
  };

  const [eventId, error] = useStore(
    getOrCreateMethodStore(getEventIdBySlug, options, initialEventId),
  );
  if (error) throw error;
  if (!eventId) return null;

  console.log('EventBySlugProvider eventId', eventId);

  return (
    <EventByIdProvider
      eventId={eventId}
      initialState={initialState}
      children={children}
    />
  );
}
