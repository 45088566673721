import { EventDoc } from '@livekatsomo/models';
import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { EventsContext } from '@livekatsomo/web/contexts';
import {
  addEvent,
  deleteEvent,
  getEvents,
  validateEventSlug,
} from '@livekatsomo/web/data';
import { QueryConstraint, orderBy } from 'firebase/firestore';
import { ReactNode, Suspense, useState } from 'react';

interface EventsProviderProps {
  children: ReactNode;
  queryConstraints?: QueryConstraint[];
  initialState?: EventDoc[];
}

export function EventsProvider({
  children,
  initialState,
  queryConstraints: initialQueryConstraints,
}: EventsProviderProps) {
  const [queryConstraints, setQueryConstraints] = useState<QueryConstraint[]>(
    initialQueryConstraints || [orderBy('startDate', 'desc')],
  );

  const options: Parameters<typeof getEvents>[0] = {
    queryConstraints: [...queryConstraints],
  };

  const store = getOrCreateMethodStore(getEvents, options, initialState);

  return (
    <EventsContext.Provider
      value={{
        store,
        setQueryConstraints,
        addEvent,
        validateEventSlug,
        deleteEvent,
      }}
    >
      <Suspense>{children}</Suspense>
    </EventsContext.Provider>
  );
}
