import Upload from '@mui/icons-material/Upload';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';

/**
 * Props for the UploadFileOverlay component.
 */
export interface UploadFileOverlayProps {
  /**
   * Indicates whether a file is being dragged over the component.
   */
  isDragActive: boolean;
  /**
   * Indicates whether a dragged file is rejected by the component.
   */
  isDragReject: boolean;
}

/**
 * Renders an overlay for uploading files.
 * @param {UploadFileOverlayProps} props - The props for the component.
 * @param {boolean} props.isDragActive - Whether a file is being dragged over the overlay.
 * @param {boolean} props.isDragReject - Whether the dragged file is rejected.
 * @returns The UploadFileOverlay component.
 */
export function UploadFileOverlay({
  isDragActive,
  isDragReject,
}: UploadFileOverlayProps) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        textAlign: 'center',
        display: isDragActive ? 'flex' : 'none',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: isDragReject
          ? (theme) => alpha(theme.palette.error.main, 0.8)
          : (theme) => alpha(theme.palette.grey[900], 0.8),
        color: 'common.white',
      }}
    >
      <Typography color="grey.100" variant="h4">
        {isDragReject ? t('Filetype not accepted') : t('Drop here')}
      </Typography>
      <Upload fontSize="large" />
    </Box>
  );
}
