import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AssetDoc,
  AssetFormValues,
  assetFormValuesSchema,
} from '@livekatsomo/models';
import { FileUploadProgess, UploadProgressFunction } from '@livekatsomo/types';
import { useEnvironment } from '@livekatsomo/web/hooks';
import {
  ImageUploadButtonField,
  MultipleSelectCheckboxField,
  TextField,
} from '@livekatsomo/web/ui-components/react-hook-form-components';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';

/**
 * Props for the AssetForm component.
 */
export interface AssetFormProps {
  /**
   * The asset to be displayed in the form. Can be null if creating a new asset.
   */
  asset: AssetDoc | null;
  /**
   * Function to be called when the form is submitted.
   * @param assetFormValues - The values of the asset form.
   * @param setProgress - A function to set the upload progress of the asset.
   * @returns A promise that resolves when the submission is complete.
   */
  onSubmit: (
    assetFormValues: AssetFormValues,
    setProgress: UploadProgressFunction,
  ) => Promise<void>;
  /**
   * Function to be called when the form is closed.
   */
  onClose: () => void;
}

/**
 * A form component for adding or updating an asset.
 *
 * @param asset - The asset to be updated, or null if a new asset is being added.
 * @param onSubmit - A function that will be called when the form is submitted. It takes the validated form data and a function to update the upload progress.
 * @param onClose - A function that will be called when the form is closed.
 */
export function AssetForm({ asset, onSubmit, onClose }: AssetFormProps) {
  const { t } = useTranslation();
  z.setErrorMap(makeZodI18nMap({ t, handlePath: { ns: ['common', 'zod'] } }));
  const [errorMessage, setErrorMessage] = useState<string>();
  const [uploadProgress, setProgress] = useState<FileUploadProgess>({});
  const initialValues = {
    alt: '',
    description: '',
    purpose: [],
    files: [] as File[],
    ...asset,
  };
  const { development } = useEnvironment();

  const handleClose = () => {
    setErrorMessage(undefined);
    setProgress({});
    onClose();
  };

  const submit = async (values: typeof initialValues) => {
    console.log('form submitted', values);

    try {
      const validatedData = assetFormValuesSchema.parse(values);
      await onSubmit(validatedData, setProgress);
      onClose();
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  const methods = useForm({
    resolver: zodResolver(assetFormValuesSchema),
    // resolver: async (data, context, options) => {
    //   const validationStatus = await zodResolver(assetFormValuesSchema)(
    //     data,
    //     context,
    //     options
    //   );
    //   console.log('validationStatus', validationStatus, data);
    //   return validationStatus;
    // },
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const {
    formState: { isDirty, isSubmitting, isValid },
    setValue,
    reset,
    handleSubmit,
    watch,
  } = methods;

  const purpose = watch('purpose');

  return (
    <FormProvider {...methods}>
      <DevTool control={methods.control} />
      <form onSubmit={handleSubmit(submit)} onReset={() => reset()}>
        <DialogContent>
          <DialogContentText
            sx={{
              mb: 2,
            }}
          >
            {t('Add asset details here.')}
          </DialogContentText>
          {!asset ? (
            <ImageUploadButtonField
              uploadProgress={uploadProgress}
              name="files"
            />
          ) : null}
          <TextField
            autoFocus
            margin="dense"
            name="alt"
            label={t('Alt')}
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            name="description"
            label={t('Description')}
            type="text"
            fullWidth
            variant="standard"
          />
          <MultipleSelectCheckboxField
            name="purpose"
            options={['logo', 'background', 'poster', 'banner']}
            label={t('Purpose')}
          />

          {errorMessage ? (
            <Typography
              color="error"
              variant="body1"
              sx={{
                textAlign: 'center',
                mt: 2,
              }}
            >
              {errorMessage}
            </Typography>
          ) : null}
        </DialogContent>
        <DialogActions>
          {development ? (
            <Button
              type="button"
              variant="outlined"
              onClick={async (e) => {
                const generateAssetFormContent = (
                  await import(
                    '@livekatsomo/web/ui-components/random-data-generators'
                  )
                ).generateAssetFormContent;
                generateAssetFormContent(setValue, purpose);
              }}
            >
              {t('Generate')}
            </Button>
          ) : null}
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button type="reset" disabled={isSubmitting || !isDirty}>
            {t('Reset')}
          </Button>
          <LoadingButton
            type="submit"
            disabled={!isValid || isSubmitting || !isDirty}
            loading={isSubmitting}
          >
            {asset ? t('Update') : t('Add')}
          </LoadingButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
}
