import { ThemeEditorContext } from '@livekatsomo/web/contexts';
import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { getTheme, updateTheme } from '@livekatsomo/web/data';
import { Suspense } from 'react';

export const ThemeEditorContextProvider = ({
  children,
  themeId,
}: {
  children: JSX.Element;
  themeId: string;
}) => {
  if (!themeId) {
    throw new Error('ThemeEditorContextProvider requires a themeId');
  }

  const options: Parameters<typeof getTheme>[0] = {
    themeId,
  };
  const store = getOrCreateMethodStore(getTheme, options);

  return (
    <ThemeEditorContext.Provider
      value={{
        store,
        updateTheme: (theme) => updateTheme(themeId, theme),
      }}
    >
      <Suspense>{children}</Suspense>
    </ThemeEditorContext.Provider>
  );
};
