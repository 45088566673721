import { EventDoc, TrackDoc } from '@livekatsomo/models';
import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { SlideControllerContext } from '@livekatsomo/web/contexts';
import {
  deleteSlideQueueItem,
  getSlideQueue,
  queueSlide,
  updateSlideQueue,
  updateSlideQueueItem,
} from '@livekatsomo/web/data';
import { QueryConstraint } from 'firebase/firestore';
import { Suspense, useState } from 'react';
import { Swiper } from 'swiper/types';

interface SlideControllerProviderProps {
  event: EventDoc;
  track: TrackDoc;
  children: JSX.Element;
  queryConstraints?: QueryConstraint[];
}

export function SlideControllerProvider({
  event,
  track,
  children,
}: SlideControllerProviderProps) {
  const [delay, setDelay] = useState(30);
  const [thumbsSwiper, setThumbsSwiper] = useState<Swiper | null>(null);
  const [mainSwiper, setMainSwiper] = useState<Swiper | null>(null);

  if (!event) throw new Error('event is required');
  if (!track) throw new Error('track is required');

  const options: Parameters<typeof getSlideQueue>[0] = {
    eventId: event.id,
    trackId: track.id,
    // sessionNumber: track.sessionNumber || 0,
  };

  const store = getOrCreateMethodStore(getSlideQueue, options);

  return (
    <SlideControllerContext.Provider
      value={{
        store,
        delay,
        thumbsSwiper,
        mainSwiper,
        setThumbsSwiper,
        setMainSwiper,
        setDelay,
        updateSlideQueue: (data) => updateSlideQueue(event.id, track.id, data),
        updateSlideQueueItem: (id, data) =>
          updateSlideQueueItem(event.id, track.id, id, data),
        deleteSlideQueueItem: (id) =>
          deleteSlideQueueItem(event.id, track.id, id),
        queueSlide: (asset) =>
          queueSlide(event.id, track.id, asset, track.sessionNumber || 0),
      }}
    >
      <Suspense>{children}</Suspense>
    </SlideControllerContext.Provider>
  );
}
