import { TrackDoc } from '@livekatsomo/models';
import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { SlideDeckContext } from '@livekatsomo/web/contexts';
import { getSlides } from '@livekatsomo/web/data';
import { QueryConstraint } from 'firebase/firestore';
import { Suspense, useState } from 'react';
import { Swiper } from 'swiper/types';

interface SlidesProviderProps {
  slideDeckId?: string;
  eventId: string;
  track: TrackDoc;
  children: JSX.Element;
  queryConstraints?: QueryConstraint[];
}

declare global {
  // var must be used for global scopes
  // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#type-checking-for-globalthis

  // eslint-disable-next-line no-var
  var mainSwiper: Swiper;
}

export function SlideDeckProvider({
  eventId,
  track,
  slideDeckId,
  children,
}: SlidesProviderProps) {
  const [currentSlideDeckId, setSlideDeckId] = useState(
    slideDeckId || track?.currentSlideDeckId,
  );
  const [thumbsSwiper, setThumbsSwiper] = useState<Swiper>();
  const [mainSwiper, setMainSwiper] = useState<Swiper>();

  if (!eventId) throw new Error('eventId is required');
  if (!track) throw new Error('track is required');

  const options: Parameters<typeof getSlides>[0] = {
    eventId: eventId,
    trackId: track.id,
    slideDeckId: currentSlideDeckId,
  };

  if (mainSwiper) {
    globalThis.mainSwiper = mainSwiper;
  }

  const store = getOrCreateMethodStore(getSlides, options);

  return (
    <SlideDeckContext.Provider
      value={{
        store,
        thumbsSwiper,
        mainSwiper,
        currentSlideDeckId,
        setSlideDeckId,
        setThumbsSwiper,
        setMainSwiper,
      }}
    >
      <Suspense>{children}</Suspense>
    </SlideDeckContext.Provider>
  );
}
