import { hashInt, hslToRgb } from '@livekatsomo/shared/utils';
import Chip from '@mui/material/Chip';

/**
 * Props for the AssetChip component.
 */
export interface AssetChipProps {
  /**
   * The purpose of the asset.
   * @example 'logo'
   */
  purpose: string;
}

/**
 * Renders a chip component for an asset with a given purpose.
 * @param {AssetChipProps} props - The props object containing the purpose of the asset.
 * @returns  The JSX element representing the asset chip.
 */
export function AssetChip({ purpose }: AssetChipProps) {
  const hue = hashInt(purpose, 360);
  const bgColor = hslToRgb(hue / 360, 1, 0.8);
  return (
    <Chip
      component="span"
      label={purpose}
      size="small"
      sx={{
        backgroundColor: bgColor,
        color: 'grey.900',
        ml: 1,
        my: 0.5,
      }}
    />
  );
}
