import { firestorePaths } from '@livekatsomo/shared/config';
import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { CustomerThemesContext } from '@livekatsomo/web/contexts';
import {
  copyTheme,
  getThemes,
  updateTheme,
  addTheme,
  setAsCurrentTheme,
  getThemeUsedIn,
  deleteTheme,
} from '@livekatsomo/web/data';
import { QueryConstraint } from 'firebase/firestore';
import { Suspense } from 'react';

interface CustomerThemesProviderProps {
  customerId: string;
  children: JSX.Element;
  queryConstraints?: QueryConstraint[];
}

export function CustomerThemesProvider({
  customerId,
  children,
}: CustomerThemesProviderProps) {
  if (!customerId) throw new Error('parentId is required');

  const options: Parameters<typeof getThemes>[0] = {
    customerId,
  };

  const store = getOrCreateMethodStore(getThemes, options);

  return (
    <CustomerThemesContext.Provider
      value={{
        store,
        updateTheme: (themeId, theme) => updateTheme(themeId, theme),
        addTheme: (theme) => addTheme(customerId, theme),
        setAsCurrent: (theme) =>
          setAsCurrentTheme(theme, firestorePaths.customerPath(customerId)),
        copyTheme,
        getThemeUsedIn,
        deleteTheme,
      }}
    >
      <Suspense>{children}</Suspense>
    </CustomerThemesContext.Provider>
  );
}
