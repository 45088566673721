import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { TracksContext } from '@livekatsomo/web/contexts';
import {
  getTracks,
  addTrack,
  updateTrack,
  deleteTrack,
  updateTrackOrder,
  validateTrackSlug,
} from '@livekatsomo/web/data';
import { QueryConstraint } from 'firebase/firestore';
import { Suspense } from 'react';

export interface TracksProviderProps {
  eventId: string;
  children: JSX.Element;
  queryConstraints?: QueryConstraint[];
}

export function TracksProvider({ eventId, children }: TracksProviderProps) {
  if (!eventId) throw new Error('eventId and customerId is required');

  const options: Parameters<typeof getTracks>[0] = {
    eventId,
  };

  const store = getOrCreateMethodStore(getTracks, options);

  return (
    <TracksContext.Provider
      value={{
        store,
        addTrack: (track, setProgress) => addTrack(eventId, track, setProgress),
        updateTrack: (trackId, track, setProgress) =>
          updateTrack(eventId, trackId, track, setProgress),
        deleteTrack: (trackId) => deleteTrack(eventId, trackId),
        updateOrder: (tracks) => updateTrackOrder(eventId, tracks),
        validateTrackSlug,
      }}
    >
      <Suspense>{children}</Suspense>
    </TracksContext.Provider>
  );
}
