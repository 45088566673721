import { TrackPlaybackHistory, User } from '@livekatsomo/models';
import { UserContext } from '@livekatsomo/web/contexts';
import {
  requestUserDeletion,
  storeHistory,
  updateUserPhoto,
  updateUserProfile,
} from '@livekatsomo/web/data';
import { useAuthentication } from '@livekatsomo/web/data-hooks';
import { Suspense } from 'react';

type UserProviderProps = {
  children: JSX.Element;
  initialState?: User;
};

export function UserProvider({ children, initialState }: UserProviderProps) {
  const { reauthenticate, user } = useAuthentication();

  if (user?.uid) {
    return (
      <UserContext.Provider
        value={{
          user,
          uploadUserPhoto: updateUserPhoto,
          updateUserProfile: (user, onUploadProgress) =>
            updateUserProfile(reauthenticate, user, onUploadProgress),
          storeHistory: (history: Omit<TrackPlaybackHistory, 'timestamp'>) =>
            storeHistory(user?.uid, history),
          deleteUser: requestUserDeletion,
        }}
      >
        <Suspense>{children}</Suspense>
      </UserContext.Provider>
    );
  } else {
    return (
      <UserContext.Provider
        value={{
          user: null,
          updateUserProfile: () =>
            Promise.reject('userId is required to update user profile'),
          uploadUserPhoto: () =>
            Promise.reject('userId is required to upload user photo'),
          storeHistory: () =>
            Promise.reject('userId is required to store history'),
          deleteUser: () => Promise.reject('userId is required to delete user'),
        }}
      >
        <Suspense>{children}</Suspense>
      </UserContext.Provider>
    );
  }
}
