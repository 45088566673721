import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { UsersContext } from '@livekatsomo/web/contexts';
import { getUsers, updateUser } from '@livekatsomo/web/data';
import { orderBy, QueryConstraint, where } from 'firebase/firestore';
import { Suspense, useState } from 'react';

interface UsersProviderProps {
  children: JSX.Element;
}

export function UsersProvider({ children }: UsersProviderProps) {
  const [queryConstraints, setQueryConstraints] = useState<QueryConstraint[]>([
    orderBy('displayName', 'asc'),
    where('isAnonymous', '==', false),
  ]);

  const options: Parameters<typeof getUsers>[0] = {
    queryConstraints,
  };

  const store = getOrCreateMethodStore(getUsers, options);

  return (
    <UsersContext.Provider value={{ store, setQueryConstraints, updateUser }}>
      <Suspense>{children}</Suspense>
    </UsersContext.Provider>
  );
}
