import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { StreamsContext } from '@livekatsomo/web/contexts';
import {
  getStreams,
  addStream,
  updateStream,
  deleteStream,
  getStreamUsedIn,
} from '@livekatsomo/web/data';
import { QueryConstraint } from 'firebase/firestore';
import { Suspense } from 'react';

interface StreamsProviderProps {
  customerId: string;
  trackId?: string;
  children: JSX.Element;
  queryConstraints?: QueryConstraint[];
}

export function StreamsProvider({
  customerId,
  trackId,
  children,
}: StreamsProviderProps) {
  if (!customerId) throw new Error('customerId is required');

  const options: Parameters<typeof getStreams>[0] = {
    customerId,
    trackId,
  };

  const store = getOrCreateMethodStore(getStreams, options);

  return (
    <StreamsContext.Provider
      value={{
        store,
        addStream: (stream) =>
          addStream({ customerId, streamFormData: stream, trackId }),
        updateStream: (stream) => updateStream(customerId, stream),
        deleteStream: (streamId) => deleteStream(customerId, streamId),
        getStreamUsedIn: (streamId) => getStreamUsedIn(customerId, streamId),
      }}
    >
      <Suspense>{children}</Suspense>
    </StreamsContext.Provider>
  );
}
