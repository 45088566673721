import { CustomerDoc } from '@livekatsomo/models';
import { getOrCreateMethodStore, useStore } from '@livekatsomo/suspense';
import { CustomerContext } from '@livekatsomo/web/contexts';
import {
  getCustomerById,
  getCustomerIdBySlug,
  updateCustomer,
  uploadCustomerImage,
  validateCustomerSlug,
  updateCustomerLayout,
} from '@livekatsomo/web/data';
import { QueryConstraint } from 'firebase/firestore';

type CustomerProviderProps = {
  children: JSX.Element;
  initialState?: CustomerDoc;
  id: string;
} & ({ id: string } | { slug: string });

export function CustomerByIdProvider({
  children,
  id,
  initialState,
}: CustomerProviderProps) {
  if (!id) {
    throw new Error('CustomerProvider requires a id');
  }

  const options: Parameters<typeof getCustomerById>[0] = {
    customerId: id,
  };

  const store = getOrCreateMethodStore(getCustomerById, options, initialState);

  return (
    <CustomerContext.Provider
      value={{
        store,
        uploadCustomerLogo: uploadCustomerImage,
        updateCustomer,
        updateCustomerLayout: (layout) => updateCustomerLayout(id, layout),
        validateCustomerSlug,
      }}
      children={children}
    />
  );
}

type CustomerBySlugProviderProps = {
  children: JSX.Element;
  initialState?: CustomerDoc;
  initialCustomerId?: string;
  queryConstraints?: QueryConstraint[];
  slug: string;
} & ({ id: string } | { slug: string });

export function CustomerBySlugProvider({
  children,
  slug,
  initialState,
  queryConstraints,
  initialCustomerId,
}: CustomerBySlugProviderProps) {
  if (!slug) {
    throw new Error('CustomerProvider requires a slug');
  }

  const options: Parameters<typeof getCustomerIdBySlug>[0] = {
    slug,
    queryConstraints,
  };

  const [customerId, error] = useStore(
    getOrCreateMethodStore(getCustomerIdBySlug, options, initialCustomerId),
  );
  if (error) throw error;
  if (!customerId) return null;

  console.log('CustomerBySlugProvider customerId', customerId);

  return (
    <CustomerByIdProvider
      id={customerId}
      initialState={initialState}
      children={children}
    />
  );
}
