import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { PreAuthorizedUsersContext } from '@livekatsomo/web/contexts';
import {
  addPreAuthorizedUser,
  getPreAuthorizedUsers,
  deletePreAuthorizedUser,
} from '@livekatsomo/web/data';
import { orderBy, QueryConstraint } from 'firebase/firestore';
import { Suspense, useState } from 'react';

interface PreAuthorizedUsersProviderProps {
  children: JSX.Element;
}

export function PreAuthorizedUsersProvider({
  children,
}: PreAuthorizedUsersProviderProps) {
  const [queryConstraints, setQueryConstraints] = useState<QueryConstraint[]>([
    orderBy('displayName', 'asc'),
  ]);

  const options = {
    queryConstraints,
  };

  const store = getOrCreateMethodStore(getPreAuthorizedUsers, options);

  return (
    <PreAuthorizedUsersContext.Provider
      value={{
        store,
        setQueryConstraints,
        addPreAuthorizedUser,
        updatePreAuthorizedUser: addPreAuthorizedUser,
        deletePreAuthorizedUser,
      }}
    >
      <Suspense>{children}</Suspense>
    </PreAuthorizedUsersContext.Provider>
  );
}
