import {
  getTrackPlaybackHistory,
  deletePlaybackHistoryItem,
} from '@livekatsomo/web/data';
import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { TrackPlaybackHistoryContext } from '@livekatsomo/web/contexts';
import { Suspense } from 'react';

type TrackPlaybackHistoryProviderProps = {
  children: JSX.Element;
  userId?: string;
  trackId: string;
};

export function TrackPlaybackHistoryProvider({
  children,
  userId,
  trackId,
}: TrackPlaybackHistoryProviderProps) {
  if (userId) {
    const options: Parameters<typeof getTrackPlaybackHistory>[0] = {
      userId,
      trackId,
    };

    const store = getOrCreateMethodStore(getTrackPlaybackHistory, options);

    return (
      <TrackPlaybackHistoryContext.Provider
        value={{
          store,
          deletePlaybackHistoryItem: (id) =>
            deletePlaybackHistoryItem(userId, id),
        }}
      >
        <Suspense>{children}</Suspense>
      </TrackPlaybackHistoryContext.Provider>
    );
  } else {
    return (
      <TrackPlaybackHistoryContext.Provider
        value={{
          store: null,
          deletePlaybackHistoryItem: (id) => {
            throw new Error(
              'Cannot delete playback history item without userId',
            );
          },
        }}
      >
        <Suspense>{children}</Suspense>
      </TrackPlaybackHistoryContext.Provider>
    );
  }
}
