import { ChatMessageDoc, User } from '@livekatsomo/models';
import { ChatContext } from '@livekatsomo/web/contexts';
import {
  getChatMessages,
  sendChatMessage,
  updateChatMessage,
  deleteChatMessage,
  acceptChatMessage,
  rejectChatMessage,
} from '@livekatsomo/web/data';
import { QueryConstraint } from 'firebase/firestore';
import { ReactNode, Suspense, useState } from 'react';
import { getOrCreateMethodStore } from '@livekatsomo/suspense';

const notLoggedInRejection = () => Promise.reject('User not logged in');

interface ChatsProviderProps {
  user: User | null;
  children: ReactNode;
  parentPath: string;
  queryConstraints?: QueryConstraint[];
  initialState?: ChatMessageDoc[];
}

export function ChatProvider({
  user,
  children,
  parentPath,
  initialState,
  queryConstraints: initialQueryConstraints,
}: ChatsProviderProps) {
  const [queryConstraints, setQueryConstraints] = useState<QueryConstraint[]>(
    initialQueryConstraints || [],
  );

  const options: Parameters<typeof getChatMessages>[0] = {
    parentPath,
    queryConstraints: [...queryConstraints],
  };

  const store = getOrCreateMethodStore(getChatMessages, options, initialState);

  return (
    <ChatContext.Provider
      value={{
        store,
        sendChatMessage: (message, setProgress) =>
          user
            ? sendChatMessage(parentPath, user, message, setProgress)
            : notLoggedInRejection(),
        updateChatMessage: (message, setProgress) =>
          user
            ? updateChatMessage(parentPath, message.id, message, setProgress)
            : notLoggedInRejection(),
        deleteChatMessage: (messageId) =>
          user
            ? deleteChatMessage(parentPath, messageId)
            : notLoggedInRejection(),
        acceptChatMessage: (messageId) =>
          user
            ? acceptChatMessage(parentPath, user, messageId)
            : notLoggedInRejection(),
        rejectChatMessage: (messageId) =>
          user
            ? rejectChatMessage(parentPath, user, messageId)
            : notLoggedInRejection(),
        setQueryConstraints,
      }}
    >
      <Suspense>{children}</Suspense>
    </ChatContext.Provider>
  );
}
