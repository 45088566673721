import { DocumentEditorContext } from '@livekatsomo/web/contexts';
import {
  getInitialDocumentContent,
  listenFirestoreUpdates,
  listenToRemoteCursorUpdates,
  removeCursorPositionOnDestroy,
  saveLatestDocument,
  sendSteps,
  updateUserCursorPosition,
} from '@livekatsomo/web/firebase';

export interface DocumentEditorContextProviderProps {
  docPath: string;
  children: JSX.Element;
}

export function DocumentEditorContextProvider({
  docPath,
  children,
}: DocumentEditorContextProviderProps) {
  return (
    <DocumentEditorContext.Provider
      value={{
        listenToDocumentUpdates: (options) =>
          listenFirestoreUpdates({
            docPath,
            ...options,
          }),
        getInitialDocumentContent: () => getInitialDocumentContent(docPath),
        saveDocumentContent: (content, version) =>
          saveLatestDocument(docPath, content, version),
        sendDocumentUpdateSteps: (version, steps, clientID) => {
          sendSteps(version, steps, clientID, docPath);
        },
        listenToRemoteCursorUpdates: (options) =>
          listenToRemoteCursorUpdates({
            docPath,
            ...options,
          }),
        removeCursorPositionOnDestroy: (clientID) =>
          removeCursorPositionOnDestroy(docPath, clientID),
        sendCurrentCursorPosition: (...args) =>
          updateUserCursorPosition(docPath, ...args),
      }}
    >
      {children}
    </DocumentEditorContext.Provider>
  );
}
