import { zodResolver } from '@hookform/resolvers/zod';
import {
  AssetDoc,
  assetInDocumentSchema,
  imageFileSchema,
} from '@livekatsomo/models';
import { FileUploadProgess, UploadProgressFunction } from '@livekatsomo/types';
import { useEnvironment } from '@livekatsomo/web/hooks';
import {
  AssetSelectField,
  ImageUploadButtonField,
  TextField,
} from '@livekatsomo/web/ui-components/react-hook-form-components';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

/**
 * Props for the SelectAssetDialog component.
 */
export interface SelectAssetDialogProps {
  /**
   * Whether the dialog is open or not.
   */
  open: boolean;
  /**
   * The currently selected asset.
   */
  asset?: AssetDoc;
  /**
   * An array of available assets.
   */
  assets: AssetDoc[];
  /**
   * Function to be called when the dialog is closed.
   */
  onClose: () => void;
  /**
   * Function to be called when the user submits the selected asset.
   * @param value - The selected asset or an array of files.
   * @param setProgress - Optional function to set the upload progress.
   */
  onSubmit: (
    value: { asset: AssetDoc | File[]; alt?: string },
    setProgress?: UploadProgressFunction,
  ) => void;
}

/**
 * A Zod schema for validating form data for the SelectAssetDialog component.
 *
 * @remarks
 * The schema validates that the form data contains an asset (either an image file or an asset in the document),
 * and that the alt text is not empty if the asset is an image file.
 */
const formSchema = z
  .object({
    asset: imageFileSchema.or(assetInDocumentSchema),
    alt: z.string(),
  })
  .refine(
    (data) => {
      if (Array.isArray(data.asset) && data.asset.length === 0) {
        return false;
      }
      return true;
    },
    {
      path: ['asset'],
      params: {
        i18n: { key: 'Please select a file' },
      },
    },
  )
  .refine(
    (data) => {
      if (Array.isArray(data.asset) && data.alt === '') {
        return false;
      }
      return true;
    },
    {
      params: {
        i18n: { key: 'Alt text is required' },
      },
      path: ['alt'],
    },
  );

/**
 * A dialog component for selecting or uploading an asset.
 *
 * @param open - Whether the dialog is open or not.
 * @param asset - The initial asset to display in the dialog.
 * @param assets - An array of assets to display in the asset select field.
 * @param onClose - A function to call when the dialog is closed.
 * @param onSubmit - A function to call when the form is submitted.
 */
export function SelectAssetDialog({
  open: isOpen,
  asset: initialAsset,
  assets,
  onClose,
  onSubmit,
}: SelectAssetDialogProps) {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [uploadProgress, setProgress] = useState<FileUploadProgess>({});
  const initialValues = {
    asset: initialAsset || ([] as File[]),
    alt: '',
  };
  const { development } = useEnvironment();

  const { t } = useTranslation();

  const handleClose = () => {
    setErrorMessage(undefined);
    setProgress({});
    onClose();
  };

  const submit = async (values: z.infer<typeof formSchema>) => {
    console.log('form submitted', values);

    if (!values.asset) {
      return;
    }

    try {
      onSubmit(
        {
          asset: values.asset,
          alt: values.alt,
        },
        setProgress,
      );
      handleClose();
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  const methods = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const {
    formState: { isDirty, isSubmitting, isValid },
    setValue,
    reset,
    handleSubmit,
    watch,
  } = methods;

  const asset = watch('asset');

  return (
    <Dialog scroll="body" open={isOpen} onClose={handleClose}>
      <Box sx={{ position: 'relative' }}>
        <DialogTitle>{t('Select Asset')}</DialogTitle>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submit)} onReset={() => reset()}>
            <DialogContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <DialogContentText sx={{ mb: 2 }}>
                {t('Select or upload an asset')}
              </DialogContentText>
              <AssetSelectField
                assets={assets}
                name="asset"
                label={t('Select asset')}
              />
              <Divider>{t('OR')}</Divider>
              <ImageUploadButtonField
                uploadProgress={uploadProgress}
                name="asset"
              />
              {/* If uploading new file, show alt input */}
              {Array.isArray(asset) ? (
                <TextField
                  margin="dense"
                  name="alt"
                  label={t('Alt')}
                  type="text"
                  fullWidth
                  required
                  variant="standard"
                />
              ) : null}
              {errorMessage ? (
                <Typography
                  color="error"
                  variant="body1"
                  sx={{ textAlign: 'center', mt: 2 }}
                >
                  {errorMessage}
                </Typography>
              ) : null}
            </DialogContent>
            <DialogActions>
              {development ? (
                <Button
                  type="button"
                  variant="outlined"
                  onClick={async (e) => {
                    const generateRandomImageForUpload = (
                      await import(
                        '@livekatsomo/web/ui-components/random-data-generators'
                      )
                    ).generateRandomImageForUpload;
                    generateRandomImageForUpload(setValue);
                  }}
                >
                  {t('Generate')}
                </Button>
              ) : null}
              <Button onClick={handleClose}>{t('Cancel')}</Button>
              <Button type="reset" disabled={isSubmitting || !isDirty}>
                {t('Reset')}
              </Button>
              <LoadingButton
                type="submit"
                disabled={!isValid || !isDirty || isSubmitting}
                loading={isSubmitting}
              >
                {t('Select')}
              </LoadingButton>
            </DialogActions>
          </form>
        </FormProvider>
      </Box>
    </Dialog>
  );
}

export default SelectAssetDialog;
