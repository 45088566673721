import { KatsomoAuthError } from '@livekatsomo/custom-errors';
import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { NotificationContext } from '@livekatsomo/web/contexts';
import { getNewNotifications, sendNotification } from '@livekatsomo/web/data';
import { useAuthentication } from '@livekatsomo/web/data-hooks';
import { ReactNode, Suspense } from 'react';

export interface NotificationsProviderProps {
  eventId: string;
  trackId: string;
  children: ReactNode;
}

export function NotificationProvider({
  eventId,
  trackId,
  children,
}: NotificationsProviderProps) {
  const options: Parameters<typeof getNewNotifications>[0] = {
    eventId,
    trackId,
  };
  const { user } = useAuthentication();
  const store = getOrCreateMethodStore(getNewNotifications, options);

  return (
    <NotificationContext.Provider
      value={{
        store,
        sendNotificationMessage: async (message) => {
          if (user?.roles && !user.roles.includes('admin'))
            throw new KatsomoAuthError(
              'User not authorized to send notifications',
            );
          if (user) await sendNotification(eventId, trackId, user.uid, message);
          else return Promise.reject('User not logged in');
        },
      }}
    >
      <Suspense>{children}</Suspense>
    </NotificationContext.Provider>
  );
}
