import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { ModeratorsContext } from '@livekatsomo/web/contexts';
import {
  addModerator,
  deleteModerator,
  deletePreAuthorizedModerator,
  getModerators,
  getPreAuthorizedModerators,
} from '@livekatsomo/web/data';
import { Suspense } from 'react';

type ModeratorsProviderProps = {
  children: JSX.Element;
  parentPath: string;
};

export function ModeratorsProvider({
  children,
  parentPath,
}: ModeratorsProviderProps) {
  if (!parentPath) {
    throw new Error('ModeratorsProvider requires an parentPath');
  }

  const options: Parameters<typeof getModerators>[0] = {
    parentPath,
  };

  const moderatorsStore = getOrCreateMethodStore(getModerators, options);
  const preAuthorizedModeratorsStore = getOrCreateMethodStore(
    getPreAuthorizedModerators,
    options,
  );

  return (
    <ModeratorsContext.Provider
      value={{
        moderatorsStore,
        preAuthorizedModeratorsStore,
        addModerator: (moderator) => addModerator(parentPath, moderator),
        deleteModerator: (moderator) =>
          deleteModerator(parentPath, moderator.id),
        deletePreAuthorizedModerator: (preAuthorizedModerator) =>
          deletePreAuthorizedModerator(parentPath, preAuthorizedModerator.id),
      }}
    >
      <Suspense>{children}</Suspense>
    </ModeratorsContext.Provider>
  );
}
