import {
  getPlaybackHistory,
  deletePlaybackHistoryItem,
} from '@livekatsomo/web/data';
import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import {
  PlaybackHistoryContext,
  PlaybackHistoryContextModel,
} from '@livekatsomo/web/contexts';
import { Suspense } from 'react';

type PlaybackHistoryProviderProps = {
  children: JSX.Element;
  userId: string;
};

export function PlaybackHistoryProvider({
  children,
  userId,
}: PlaybackHistoryProviderProps) {
  const methods: Omit<PlaybackHistoryContextModel, 'store'> = {
    deletePlaybackHistoryItem: async (id: string) => {
      if (!userId) {
        throw new Error('User ID is missing');
      }
      return deletePlaybackHistoryItem(userId, id);
    },
  };

  if (userId) {
    const options: Parameters<typeof getPlaybackHistory>[0] = {
      userId,
    };

    const store = getOrCreateMethodStore(getPlaybackHistory, options);

    return (
      <PlaybackHistoryContext.Provider
        value={{
          store,
          ...methods,
        }}
      >
        <Suspense>{children}</Suspense>
      </PlaybackHistoryContext.Provider>
    );
  } else {
    return (
      <PlaybackHistoryContext.Provider
        value={{
          store: null,
          ...methods,
        }}
      >
        <Suspense>{children}</Suspense>
      </PlaybackHistoryContext.Provider>
    );
  }
}
