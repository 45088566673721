import { firestorePaths } from '@livekatsomo/shared/config';
import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { EventDownloadFilesContext } from '@livekatsomo/web/contexts';
import {
  deleteDownloadFile,
  getDownloadFiles,
  updateDownloadFile,
  uploadDownloadFileFromForm,
  uploadFiles,
} from '@livekatsomo/web/data';
import { orderBy, QueryConstraint } from 'firebase/firestore';
import { Suspense, useState } from 'react';

type DownloadFilesProviderProps = {
  eventId: string;
  children: JSX.Element;
};

export function EventDownloadFilesProvider({
  children,
  eventId,
}: DownloadFilesProviderProps) {
  const [queryConstraints, setQueryConstraints] = useState<QueryConstraint[]>([
    orderBy('filename', 'asc'),
  ]);
  const parentPath = firestorePaths.eventPath(eventId);

  const options: Parameters<typeof getDownloadFiles>[0] = {
    parentPath: parentPath || '',
    queryConstraints,
  };

  const store = getOrCreateMethodStore(getDownloadFiles, options);

  return (
    <EventDownloadFilesContext.Provider
      value={{
        store,
        parentPath,
        setQueryConstraints,
        uploadDownloadFile: async (downloadFileFormData, setProgress) =>
          uploadDownloadFileFromForm(
            downloadFileFormData,
            parentPath,
            setProgress,
          ),
        uploadFiles: (files, subCollection, setProgress) =>
          uploadFiles(files, parentPath, subCollection, setProgress),
        deleteDownloadFile: (downloadFileId) =>
          deleteDownloadFile(downloadFileId, parentPath),
        updateDownloadFile: (downloadFileId, downloadFileFormData) =>
          updateDownloadFile(downloadFileId, parentPath, downloadFileFormData),
      }}
    >
      <Suspense>{children}</Suspense>
    </EventDownloadFilesContext.Provider>
  );
}
