import { CallableFunctionResponse } from '@livekatsomo/models';
import { CloudFunctionNames, REGION } from '@livekatsomo/shared/config';
import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { InvitationsContext } from '@livekatsomo/web/contexts';
import {
  addInvitations,
  deleteInvitation,
  getInvitations,
  setInvitation,
} from '@livekatsomo/web/data';
import { getApp } from 'firebase/app';
import {
  HttpsCallableResult,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import { Suspense } from 'react';

type InvitationsProviderProps = {
  children: JSX.Element;
  parentPath: string;
};

export function InvitationsProvider({
  children,
  parentPath,
}: InvitationsProviderProps) {
  if (!parentPath) {
    throw new Error('InvitationsProvider requires an parentPath');
  }

  const options: Parameters<typeof getInvitations>[0] = {
    parentPath,
  };

  const invitationsStore = getOrCreateMethodStore(getInvitations, options);

  return (
    <InvitationsContext.Provider
      value={{
        invitationsStore,
        setInvitation: (invitation) => setInvitation(parentPath, invitation),
        addInvitations: (invitations) =>
          addInvitations(parentPath, invitations),
        deleteInvitation: (invitation) =>
          deleteInvitation(parentPath, invitation.id),
        sendPendingInvitations: () => sendPendingInvitations(parentPath),
      }}
    >
      <Suspense>{children}</Suspense>
    </InvitationsContext.Provider>
  );
}

export async function sendPendingInvitations(
  parentPath: string,
): Promise<HttpsCallableResult<CallableFunctionResponse>> {
  const functions = getFunctions(getApp(), REGION);

  return httpsCallable<{ parentPath: string }, CallableFunctionResponse>(
    functions,
    `authorizationFunctions-${CloudFunctionNames.SEND_INVITATIONS}`,
  )({ parentPath });
}
