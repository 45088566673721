import { firestorePaths } from '@livekatsomo/shared/config';
import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { CustomerAssetsContext } from '@livekatsomo/web/contexts';
import {
  deleteAsset,
  getAssetUsedIn,
  getAssets,
  updateAsset,
  uploadFiles,
  uploadAsset,
} from '@livekatsomo/web/data';
import { orderBy, QueryConstraint } from 'firebase/firestore';
import { Suspense, useState } from 'react';

type AssetsProviderProps = {
  customerId: string;
  children: JSX.Element;
};

export function CustomerAssetsProvider({
  children,
  customerId,
}: AssetsProviderProps) {
  const [queryConstraints, setQueryConstraints] = useState<QueryConstraint[]>([
    orderBy('filename', 'asc'),
  ]);
  const parentPath = firestorePaths.customerPath(customerId);

  const options: Parameters<typeof getAssets>[0] = {
    parentPath: parentPath || '',
    queryConstraints,
  };

  const store = getOrCreateMethodStore(getAssets, options);

  return (
    <CustomerAssetsContext.Provider
      value={{
        store,
        parentPath,
        setQueryConstraints,
        uploadAsset: async (assetFormData, setProgress) =>
          uploadAsset(assetFormData, parentPath, setProgress),
        uploadFiles: (files, subCollection, setProgress) =>
          uploadFiles(files, parentPath, subCollection, setProgress),
        deleteAsset: (assetId) => deleteAsset(assetId, parentPath),
        updateAsset: (assetId, assetFormData) =>
          updateAsset(assetId, parentPath, assetFormData),
        getAssetUsedIn: (assetId) => getAssetUsedIn(assetId, parentPath),
      }}
    >
      <Suspense>{children}</Suspense>
    </CustomerAssetsContext.Provider>
  );
}
