import { AssetInDocument } from '@livekatsomo/models';

/**
 * Props for the AssetImage component.
 */
export type AssetImageProps = {
  /**
   * The asset to display. Can be an `AssetInDocument` object, a string representing the asset ID, or `null`.
   */
  asset?: AssetInDocument | string | null;
  /**
   * The height of the image.
   */
  height?: string | number | undefined;
} & React.HTMLProps<HTMLPictureElement>;

/**
 * Renders an image with optional webp and png sources.
 * @param asset The asset to display. Can be an AssetInDocument object, a string URL, or null/undefined to display nothing.
 * @param alt The alt text for the image.
 * @param children Optional children to render inside the <picture> element.
 * @param height The height of the image.
 * @param style Optional additional styles to apply to the <img> element.
 * @param width The width of the image.
 * @param props Additional HTML props to apply to the <picture> element.
 * @returns The rendered <picture> element with sources and an <img> element.
 */
export function AssetImage({
  asset,
  alt,
  children,
  height,
  style,
  width,
  ...props
}: AssetImageProps) {
  if (!asset) return null;
  return (
    <picture {...props}>
      {children}
      {typeof asset !== 'string' && asset.downloadUrls?.webp1000x1000 ? (
        <source srcSet={asset.downloadUrls?.webp1000x1000} type="image/webp" />
      ) : null}
      {typeof asset !== 'string' &&
      asset.type === 'image/png' &&
      asset.downloadUrls?.png1000x1000 ? (
        <source srcSet={asset.downloadUrls?.png1000x1000} type="image/png" />
      ) : null}
      {typeof asset !== 'string' && asset.downloadUrls?.jpeg1000x1000 ? (
        <source srcSet={asset.downloadUrls?.jpeg1000x1000} type="image/jpeg" />
      ) : null}
      <img
        alt={typeof asset === 'string' ? alt : alt || asset.alt}
        height={height}
        width="100%"
        style={{ display: 'block', ...style }}
        src={typeof asset === 'string' ? asset : asset.originalUrl}
      />
    </picture>
  );
}
