import { AssetDoc } from '@livekatsomo/models';
import { useListMenu } from '@livekatsomo/web/hooks';
import {
  ListItemMenu,
  ListItemMenuProps,
} from '@livekatsomo/web/ui-components/layout';
import { UploadListItem } from '@livekatsomo/web/ui-components/upload-list-item';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AssetListItem } from '../AssetListItem/AssetListItem';
import ImageViewerModal from '../ImageViewerModal/ImageViewerModal';
import { UploadFileOverlay } from '../UploadFileOverlay/UploadFileOverlay';

/**
 * Props for the AssetsCard component.
 */
export interface AssetsCardProps
  extends Omit<ListItemMenuProps<AssetDoc>, 'selectedItem' | 'onClose'> {
  /**
   * An array of AssetDoc objects to display in the card.
   */
  assets: AssetDoc[];
  /**
   * An array of objects representing files that are currently being uploaded.
   */
  uploadingAssets: { file: File; progress: number; error?: string }[];
  /**
   * A callback function to handle when files are uploaded.
   * @param files An array of File objects representing the uploaded files.
   */
  onUploadFiles: (files: File[]) => void;
  /**
   * A callback function to handle when a new asset is added.
   */
  onAdd?: () => void;

  onClearErrored?: (file: File) => void;
}

/**
 * A card component that displays a list of assets and allows uploading new assets.
 *
 * @param assets - An array of `AssetDoc` objects to display.
 * @param uploadingAssets - An array of objects representing files that are currently being uploaded, including their progress and any errors.
 * @param onUploadFiles - A function to call when new files are dropped or selected for upload.
 * @param onDelete - An optional function to call when an asset is deleted.
 * @param onEdit - An optional function to call when an asset is edited.
 * @param onAdd - An optional function to call when a new asset is added.
 */
export function AssetsCard({
  assets,
  uploadingAssets,
  onUploadFiles,
  onDelete,
  onEdit,
  onAdd,
  onClearErrored,
}: AssetsCardProps) {
  const { selectedItem, closeMenu, openMenu } = useListMenu<AssetDoc>();
  const [imageViewerImg, setImageViewerImg] = useState<AssetDoc>();

  const { t } = useTranslation();
  const { getRootProps, isDragActive, isDragReject } = useDropzone({
    onDrop: onUploadFiles,
    noClick: true,
    accept: {
      'image/*': ['.jpeg', '.png', '.webp', '.svg'],
    },
    multiple: true,
  });

  return (
    <Card
      {...getRootProps()}
      sx={{
        position: 'relative',
        maxHeight: '100dvh',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      aria-labelledby="assets-card-header"
    >
      <CardHeader title={t(`Assets`)} id="assets-card-header" />
      <CardContent sx={{ height: '100%', overflow: 'auto', p: 0, m: 2 }}>
        <List>
          {assets.map((asset) => (
            <ListItemButton
              key={asset.id}
              onClick={() => setImageViewerImg(asset)}
            >
              <AssetListItem onMenuClick={openMenu} asset={asset} />
            </ListItemButton>
          ))}

          {uploadingAssets?.length ? (
            <ListSubheader>{t('Uploading')}</ListSubheader>
          ) : null}
          {uploadingAssets &&
            uploadingAssets.map(({ file, progress, error }) => (
              <UploadListItem
                key={file.name}
                file={file}
                progress={progress}
                error={error}
                onClearErrored={onClearErrored}
              />
            ))}
        </List>
        <UploadFileOverlay
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        />
        <ListItemMenu
          selectedItem={selectedItem}
          onClose={closeMenu}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      </CardContent>
      <CardActions>
        {onAdd ? (
          <Button size="small" onClick={onAdd}>
            {t('Import new')}
          </Button>
        ) : null}
      </CardActions>
      <ImageViewerModal
        asset={imageViewerImg}
        onClose={() => setImageViewerImg(undefined)}
      />
    </Card>
  );
}
