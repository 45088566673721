import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { EventConfigContext } from '@livekatsomo/web/contexts';
import { getEventConfig, updateEventConfig } from '@livekatsomo/web/data';
import { Suspense } from 'react';

type EventConfigProviderProps = {
  children: JSX.Element;
  eventId: string;
};

export function EventConfigProvider({
  children,
  eventId,
}: EventConfigProviderProps) {
  if (!eventId) {
    throw new Error('EventConfigProvider requires an eventId');
  }

  const options: Parameters<typeof getEventConfig>[0] = {
    eventId: eventId,
  };

  const store = getOrCreateMethodStore(getEventConfig, options);

  return (
    <EventConfigContext.Provider
      value={{
        store,
        updateEventConfig: (eventConfig) =>
          updateEventConfig(eventId, eventConfig),
      }}
    >
      <Suspense>{children}</Suspense>
    </EventConfigContext.Provider>
  );
}
