import { AssetDoc } from '@livekatsomo/models';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'next-i18next';
import { AssetForm, AssetFormProps } from './AssetForm';

/**
 * Props for the AssetDialog component.
 */
export interface AssetDialogProps extends AssetFormProps {
  /** Whether the dialog is open or not. */
  open: boolean;
  /** The asset to display in the dialog. */
  asset: AssetDoc | null;
  /** Function to call when the dialog is closed. */
  onClose: () => void;
}

/**
 * A dialog component for adding or editing an asset.
 * @param open - A boolean indicating whether the dialog is open or not.
 * @param asset - An AssetDoc object representing the asset to be edited. Null if adding a new asset.
 * @param onClose - A function to be called when the dialog is closed.
 * @param rest - Additional props to be passed to the AssetForm component.
 * @returns A React component that displays a dialog for adding or editing an asset.
 */
export function AssetDialog({
  open: isOpen,
  asset,
  onClose,
  ...rest
}: AssetDialogProps) {
  const { t } = useTranslation();
  return (
    <Dialog
      scroll="body"
      open={isOpen}
      onClose={onClose}
      aria-label={t('Assets Dialog') || 'Assets Dialog'}
    >
      <Box sx={{ position: 'relative' }}>
        <DialogTitle>{asset ? t('Edit Asset') : t('Add Asset')}</DialogTitle>
        <AssetForm {...rest} asset={asset} onClose={onClose}></AssetForm>
      </Box>
    </Dialog>
  );
}

export default AssetDialog;
