import { AssetInDocument } from '@livekatsomo/models';
import Fab from '@mui/material/Fab';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

/**
 * Props for the ImageViewerModal component.
 */
export interface ImageViewerModalProps {
  /** The asset to display in the modal. */
  asset?: AssetInDocument;
  /** Optional text to display below the image. */
  text?: string;
  /** Function to call when the modal is closed. */
  onClose: () => void;
}

/**
 * A modal that displays an image and an optional text.
 * @param {ImageViewerModalProps} props - The props for the component.
 * @returns The component.
 */
export function ImageViewerModal({
  asset,
  text,
  onClose,
}: ImageViewerModalProps) {
  const { t } = useTranslation();

  // If there is no asset, return null.
  if (!asset) return null;

  return (
    <Modal
      aria-label={t('Image viewer')}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={!!asset}
      onClose={onClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={!!asset}>
        <Paper
          sx={{
            backgroundColor: 'black',
            boxShadow: (theme) => theme.shadows[5],
            padding: 2,
            maxHeight: '100vh',
            '&:focus': {
              outline: 'none',
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflow: 'visible',
            color: 'white',
            m: 3,
          }}
        >
          <Box
            sx={[
              {
                aspectRatio: `${asset.width}/${asset.height}`,
                position: 'relative',
                overflow: 'visible',
                maxHeight: `calc(100vh - ${44 + 44}px)`,
                display: 'block',
              },
              asset.width && asset.height && asset.width > asset.height
                ? {
                    width: `calc(100vw - ${44 + 44}px)`,
                  }
                : {
                    height: `calc(100vh - ${44 + 44}px)`,
                  },
            ]}
          >
            <Image
              src={asset.originalUrl}
              alt={asset.alt || ''}
              blurDataURL={asset.blurDataURL}
              placeholder={asset.blurDataURL ? 'blur' : 'empty'}
              sizes="100vw"
              fill={true}
              style={{
                objectFit: 'contain',
              }}
            />
            <Fab
              color="secondary"
              aria-label={t('close') || 'close'}
              sx={{
                position: 'absolute',
                top: '-44px',
                right: '-44px',
                margin: 2,
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </Fab>
          </Box>
          {text ? <Typography>{text}</Typography> : null}
        </Paper>
      </Fade>
    </Modal>
  );
}

export default ImageViewerModal;
