import { getOrCreateMethodStore } from '@livekatsomo/suspense';
import { EventFormsContext } from '@livekatsomo/web/contexts';
import {
  deleteForm,
  getForms,
  setForm,
  addForm,
  getFormUsedIn,
  getSubmissions,
} from '@livekatsomo/web/data';
import { Suspense } from 'react';

type FormsProviderProps = {
  children: JSX.Element;
  eventId: string;
};

export function EventFormsProvider({ children, eventId }: FormsProviderProps) {
  if (!eventId) {
    throw new Error('FormsProvider requires an parentPath');
  }

  const options: Parameters<typeof getForms>[0] = {
    eventId,
  };

  const store = getOrCreateMethodStore(getForms, options);

  return (
    <EventFormsContext.Provider
      value={{
        store,
        updateForm: (formId, form) => setForm(eventId, formId, form),
        addForm: (form) => addForm(eventId, form),
        deleteForm: (formId) => deleteForm(eventId, formId),
        getFormUsedIn: (formId) => getFormUsedIn(eventId, formId),
        getSubmissions: (formId) => getSubmissions(eventId, formId),
      }}
    >
      <Suspense>{children}</Suspense>
    </EventFormsContext.Provider>
  );
}
