import { SuperAdminContext } from '@livekatsomo/web/contexts';
import {
  generateMissingImagesFunction,
  generateSlugsFunction,
  helloWorldFunction,
} from '@livekatsomo/web/data';

type SuperAdminProviderProps = {
  children: JSX.Element;
};

export function SuperAdminProvider({ children }: SuperAdminProviderProps) {
  return (
    <SuperAdminContext.Provider
      value={{
        generateMissingImagesFunction,
        generateSlugsFunction,
        helloWorldFunction,
        error: null,
      }}
      children={children}
    />
  );
}
